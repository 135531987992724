import React from 'react'
import { Create, SimpleForm, TextInput, DateInput } from 'react-admin'

const SubscriptionCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="/users">
        <TextInput source="profileId" />
        <DateInput
          source="expirationDate"
          label="Expiration date"
          parse={(dateString) => (dateString ? new Date(dateString).toISOString() : null)}
        />
      </SimpleForm>
    </Create>
  )
}

export default SubscriptionCreate
