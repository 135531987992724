import React from 'react'
import { Create, FormTab, TabbedForm, TextInput } from 'react-admin'

const validateConfigurationCreation = (values) => {
  const errors: { name?: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  return errors
}

export const TenantCreate = (props) => (
  <Create {...props}>
    <TabbedForm redirect="show" validate={validateConfigurationCreation}>
      <FormTab label="Détails">
        <TextInput source="name" label="Name" />
      </FormTab>
    </TabbedForm>
  </Create>
)
