import * as React from 'react'

const DisplayVideo = ({ record }) => {
  if (!record.video) {
    return <p>No video available or recorded</p>
  }
  return (
    <div>
      <video controls>
        <source src={record.video.url} type="video/mp4" />
      </video>
    </div>
  )
}

export default DisplayVideo
