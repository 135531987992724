import React from 'react'
import { DeleteWithConfirmButton, useRefresh } from 'react-admin'

export const SubscriptionDeleteButton = (props) => {
  const { record } = props
  const refresh = useRefresh()

  return record?.type === 'FREE_TRIAL' ? (
    <DeleteWithConfirmButton
      {...props}
      confirmContent="ra.message.subscriptionDeleteConfirmation"
      redirect={() => refresh()}
    />
  ) : null
}
