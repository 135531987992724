import React from 'react'
import { ReferenceField, NumberField, Show, Tab, TabbedShowLayout, TextField } from 'react-admin'
import { ShowActionsEdit, TextArrayField } from '../../config/components'

const TruckShow = (props) => (
  <Show actions={<ShowActionsEdit />} {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="id" />
        <ReferenceField label="Profil" source="profileId" reference="users" link="show">
          <TextField source="phone" />
        </ReferenceField>
        <TextField source="name" label="Name" />
        <TextField source="type" label="Type" />
        <NumberField source="length" label="Name" />
        <NumberField source="width" label="Name" />
        <NumberField source="height" label="Name" />
        <NumberField source="axleNumber" label="Name" />
        <TextField source="contentType" label="Content type" />
        <TextArrayField source="hazardousGoods" label="Hazardous goods" />
        <TextField source="tunnelCategory" label="Tunnel category" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default TruckShow
