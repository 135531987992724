import React from 'react'
import { List, Filter, TextInput } from 'react-admin'
import { ListActionsCreateExport } from '../../config/components'
import { UserDatagrid } from './UserDatagrid'

const AccountFilter = (props) => (
  <Filter {...props}>
    <TextInput label="phone" source="phone" alwaysOn />
  </Filter>
)

const UserList = (props) => {
  return (
    <>
      <List
        {...props}
        actions={<ListActionsCreateExport />}
        sort={{ field: 'createdAt', order: 'DESC' }}
        filters={<AccountFilter />}
      >
        <UserDatagrid {...props} />
      </List>
    </>
  )
}

export default UserList
