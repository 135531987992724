import React from 'react'
import {
  DateInput,
  Edit,
  FormTab,
  SaveButton,
  SelectInput,
  TabbedForm,
  TextField,
  Toolbar,
  useEditController,
} from 'react-admin'

const SubscriptionEdit = (props) => {
  const { record } = useEditController(props)
  return (
    <Edit {...props}>
      <TabbedForm
        redirect="show"
        toolbar={
          <Toolbar {...props}>
            <SaveButton />
          </Toolbar>
        }
      >
        <FormTab label="Editing">
          <TextField source="id" />
          <TextField source="profileId" />
          <SelectInput
            source="state"
            choices={[
              { id: 'ACTIVE', name: 'ACTIVE' },
              { id: 'INACTIVE', name: 'INACTIVE' },
            ]}
          />
          <TextField source="type" />
          <TextField source="platform" />
          {record && 'expirationDate' in record && (
            <DateInput
              source="expirationDate"
              label="Expiration date"
              parse={(dateString) => (dateString ? new Date(dateString).toISOString() : null)}
            />
          )}
          {record && 'token' in record && <TextField source="token" />}
          {record && 'productId' in record && <TextField source="productId" />}
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default SubscriptionEdit
