import React from 'react'
import { ReferenceField, NumberField, Show, Tab, TabbedShowLayout, TextField } from 'react-admin'
import { ShowActionsEdit } from '../../config/components'
import DisplayMapReport from '../../components/DisplayMapReport'
import DisplayVideo from '../../components/DisplayVideo'

const ReportShow = (props) => (
  <Show actions={<ShowActionsEdit editComponent="" />} {...props}>
    <TabbedShowLayout>
      <Tab label="Map">
        <DisplayMapReport />
      </Tab>
      <Tab label="Details">
        <TextField source="id" />
        <ReferenceField label="Profil" source="profileId" reference="users" link="show">
          <TextField source="phone" />
        </ReferenceField>
        <ReferenceField label="Truck" source="truckId" reference="trucks" link="show">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="speedLimit" label="Speed limit" />
        <TextField source="comment" label="Comment" />
        <TextField source="userAgent" label="User Agent" />
        <TextField source="appVersion" label="App version" />
      </Tab>
      <Tab label="Video">
        <DisplayVideo />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default ReportShow
