import React from 'react'
import { Datagrid, List, TextField, ReferenceField } from 'react-admin'
import { ListActionsCreateExport } from '../../config/components'

const TruckList = (props) => {
  return (
    <>
      <List
        {...props}
        actions={<ListActionsCreateExport createButton="" />}
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <Datagrid rowClick="show">
          <TextField source="id" />
          <ReferenceField label="Profil" source="profileId" reference="users" link="show">
            <TextField source="phone" />
          </ReferenceField>
          <TextField source="name" label="Name" />
        </Datagrid>
      </List>
    </>
  )
}

export default TruckList
