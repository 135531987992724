import React from 'react'
import {
  Edit, FormTab, TabbedForm, TextInput,
} from 'react-admin'

const UserEdit = (props) => (
  <Edit {...props}>
    <TabbedForm redirect="show">
      <FormTab label="Détails">
        <TextInput source="phone" label="Téléphone" />
      </FormTab>
    </TabbedForm>
  </Edit>
)

export default UserEdit
