import React from 'react'
import { ReferenceManyField, TopToolbar, EditButton, CreateButton, ExportButton, RefreshButton } from 'react-admin'
import Chip from '@material-ui/core/Chip'

export const ReferenceManyFieldNoLabel = (props) => <ReferenceManyField {...props} />

ReferenceManyFieldNoLabel.defaultProps = {
  addLabel: false,
}

export const ShowActionsEdit = ({ basePath, data, resource, editComponent }) => (
  <TopToolbar>
    {editComponent !== undefined ? editComponent : <EditButton basePath={basePath} record={data} />}
  </TopToolbar>
)

export const ListActionsCreate = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
  </TopToolbar>
)

export const ListActionsCreateExport = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  createButton,
}) => (
  <TopToolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    {createButton !== undefined ? createButton : <CreateButton basePath={basePath} />}
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={total}
    />
    <RefreshButton />
  </TopToolbar>
)

export const TextArrayField = ({ record, source }) => (
  <>
    {record[source].map((item) => (
      <Chip label={item} key={item} />
    ))}
  </>
)
TextArrayField.defaultProps = { addLabel: true }
