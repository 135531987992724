import axios from 'axios'

const httpClient = (params = {}) => {
  const options = params
  if (!params.headers) {
    options.headers = { Accept: 'application/json' }
  }
  options.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
  return axios(options).catch((err) => {
    const errorCode = err?.response?.data?.code

    if (['WRONG_TOKEN', 'WRONG_CREDENTIALS'].includes(errorCode)) {
      localStorage.setItem('needsRefresh', true)
    }

    throw Error(`ra.errorCode.${errorCode}` || 'ra.errorCode.REFRESHING')
  })
}

export default httpClient
