import { ListActionsCreateExport } from '../../config/components'
import React from 'react'
import { Datagrid, DeleteWithConfirmButton, Filter, List, TextField, TextInput } from 'react-admin'

const TenantFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="qName" alwaysOn />
  </Filter>
)

export const TenantList = (props) => {
  return (
    <>
      <List
        {...props}
        actions={<ListActionsCreateExport />}
        sort={{ field: 'name', order: 'ASC' }}
        filters={<TenantFilter />}
        bulkActionButtons={false}
      >
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="id" />
          <TextField source="nbAuthorizedPhoneNumber" label="Nombre de licences" />
          <DeleteWithConfirmButton confirmContent="Êtes vous sûr de vouloir supprimer ce tenant ?" />
        </Datagrid>
      </List>
    </>
  )
}
