import React from 'react'
import { Show, TextField, DateField, TabbedShowLayout, Tab, useEditController } from 'react-admin'
import { ShowActionsEdit } from '../../config/components'

const SubscriptionShow = (props) => {
  const { record } = useEditController(props)

  return (
    <Show actions={<ShowActionsEdit />} {...props}>
      <TabbedShowLayout>
        <Tab label="Details">
          <TextField source="id" />
          <TextField source="profileId" />
          <TextField source="state" />
          <TextField source="type" />
          <TextField source="platform" />
          {record && 'expirationDate' in record && <DateField source="expirationDate" label="Expiration date" />}
          {record && 'token' in record && <TextField source="token" />}
          {record && 'productId' in record && <TextField source="productId" />}
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default SubscriptionShow
