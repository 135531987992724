import * as React from 'react'

export default class DisplayMapFavorite extends React.Component {
  mapRef = React.createRef()
  state = {
    map: null,
  }

  componentDidMount() {
    const record = this.props.record
    const H = window.H
    const platform = new H.service.Platform({
      apikey: 'qcKy-J9wfKwyu9npVc5LXxIJSCTsEUu8egb3c3MedmU',
    })

    const defaultLayers = platform.createDefaultLayers()

    const group = new H.map.Group()

    if (record.lat && record.long) {
      const marker = new H.map.Marker({ lat: record.lat, lng: record.long })
      group.addObject(marker)
    }

    const map = new H.Map(this.mapRef.current, defaultLayers.vector.normal.map, {
      center: { lat: record.lat || 50, lng: record.long || 5 },
      zoom: 8,
      pixelRatio: window.devicePixelRatio || 1,
    })

    map.addObject(group)
    // set map size to bounding box

    // MapEvents enables the event system
    // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
    // This variable is unused and is present for explanatory purposes
    new H.mapevents.Behavior(new H.mapevents.MapEvents(map))

    // Create the default UI components to allow the user to interact with them
    // This variable is unused
    H.ui.UI.createDefault(map, defaultLayers)

    this.setState({ map })
  }

  componentWillUnmount() {
    this.state.map.dispose()
  }

  componentDidUpdate() {
    const { lat, lng, zoom } = this.props

    if (this.map) {
      // prevent the unnecessary map updates by debouncing the
      // setZoom and setCenter calls
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.map.setZoom(zoom)
        this.map.setCenter({ lat, lng })
      }, 100)
    }
  }

  render() {
    return <div ref={this.mapRef} style={{ height: '80vh', width: '80vw' }} />
  }
}
