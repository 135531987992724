import React, { useEffect, useState } from 'react'
import { SimpleForm } from 'react-admin'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import httpClient from '../providers/http/httpClient'
import configuration from '../config/config'

const Modal = (props) => {
  const [reference, setReference] = useState('')
  const [method, setMethod] = useState('post')
  const [isFormData, setIsFormData] = useState(false)

  useEffect(() => {
    setReference(props.reference)
    setMethod(props.method)
    setIsFormData(props.isFormData)
  }, [])

  const handleOnSubmit = (e) => {
    let data = e
    if (isFormData && e?.attachments?.rawFile) {
      data = new FormData()
      data.append('file', e.attachments.rawFile)
    }
    httpClient({
      method,
      data,
      url: `${configuration.apiUrl}/${reference}`,
    }).then(() => props.onClose())
  }

  return (
    <Dialog fullWidth open={props.open} onBackdropClick={props.onClose} onEscapeKeyDown={props.onClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <SimpleForm save={handleOnSubmit} children={props.children} />
      </DialogContent>
    </Dialog>
  )
}

export default Modal
