// In src/App.js
import React from 'react'
import { Admin, Resource } from 'react-admin'
import dotenv from 'dotenv'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import FavoriteIcon from '@material-ui/icons/Favorite'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import indigo from '@material-ui/core/colors/indigo'
import red from '@material-ui/core/colors/red'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { englishMessages } from './i18n/en'
import { frenchMessages } from './i18n/fr'
import HttpProvider from './providers/http/provider'
import authProvider from './providers/http/authProvider'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from './config/resources'
import UserList from './pages/users/UserList'
import UserShow from './pages/users/UserShow'
import addUploadCapabilities from './providers/http/addUploadCapabilities'
import { LoginPage } from './pages/login/LoginPage'
import SubscriptionCreate from './pages/subscriptions/SubscriptionCreate'
import UserCreate from './pages/users/UserCreate'
import UserEdit from './pages/users/UserEdit'
import ReportShow from './pages/reports/ReportShow'
import ReportList from './pages/reports/ReportList'
import TruckList from './pages/trucks/TruckList'
import TruckShow from './pages/trucks/TruckShow'
import FavoriteList from './pages/favorites/FavoriteList'
import FavoriteShow from './pages/favorites/FavoriteShow'
import { TenantList } from './pages/tenants/TenantList'
import { TenantCreate } from './pages/tenants/TenantCreate'
import { TenantEdit } from './pages/tenants/TenantEdit'
import { TenantShow } from './pages/tenants/TenantShow'
import { Grain } from '@material-ui/icons'
import { SubscriptionList } from './pages/subscriptions/SubscriptionList'
import SubscriptionShow from './pages/subscriptions/SubscriptionShow'
import SubscriptionEdit from './pages/subscriptions/SubscriptionEdit'

dotenv.config()

const messages = {
  fr: frenchMessages,
  en: englishMessages,
}
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'fr')

const CustomTheme = createMuiTheme({
  palette: {
    primary: indigo,
    secondary: {
      main: '#081C43',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
})

const App = () => (
  <Admin
    theme={CustomTheme}
    locale="fr"
    i18nProvider={i18nProvider}
    dataProvider={addUploadCapabilities(HttpProvider)}
    authProvider={authProvider}
    loginPage={LoginPage}
  >
    {(permissions) => [
      permissions === 'ADMIN' ? (
        <Resource
          name={RESOURCE_IDENTIFIERS.USER}
          list={UserList}
          show={UserShow}
          create={UserCreate}
          edit={UserEdit}
          icon={SupervisedUserCircleIcon}
        />
      ) : null,
      <Resource name={RESOURCE_IDENTIFIERS.REPORT} list={ReportList} show={ReportShow} icon={ReportProblemIcon} />,
      <Resource name={RESOURCE_IDENTIFIERS.TRUCK} list={TruckList} show={TruckShow} icon={LocalShippingIcon} />,
      <Resource name={RESOURCE_IDENTIFIERS.FAVORITE} list={FavoriteList} show={FavoriteShow} icon={FavoriteIcon} />,
      <Resource
        name={RESOURCE_IDENTIFIERS.TENANT}
        list={TenantList}
        show={TenantShow}
        create={TenantCreate}
        edit={TenantEdit}
        icon={Grain}
      />,
      <Resource name={RESOURCE_IDENTIFIERS.AUTHORIZED_PHONE_NUMBERS} />,
      <Resource
        name={RESOURCE_IDENTIFIERS.SUBSCRIPTION}
        list={SubscriptionList}
        show={SubscriptionShow}
        icon={CreditCardIcon}
        create={SubscriptionCreate}
        edit={SubscriptionEdit}
      />,
    ]}
  </Admin>
)
export default App
