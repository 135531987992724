import React, { useState } from 'react'
import {
  Button,
  Datagrid,
  DeleteButton,
  FileField,
  FileInput,
  Pagination,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  useRefresh,
} from 'react-admin'
import { IDENTIFIERS } from '../../config/resources'
import Modal from '../../components/Modal'
import { UserDatagrid } from '../users/UserDatagrid'
import { AddCircle, CloudUpload } from '@material-ui/icons'

export const TenantShow = (props) => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)
  const refresh = useRefresh()

  const AddPhoneNumberButton = () => (
    <Button label="Ajouter un nouveau numéro de téléphone autorisé" onClick={() => setShowCreateModal(true)}>
      <AddCircle />
    </Button>
  )

  const ImportPhoneNumberListButton = () => (
    <Button
      label="Importer une nouvelle liste de numéros de téléphone autorisés"
      onClick={() => setShowImportModal(true)}
    >
      <CloudUpload />
    </Button>
  )

  return (
    <>
      <Show {...props}>
        <TabbedShowLayout>
          <Tab label="Details">
            <TextField source="id" />
            <TextField source="name" label="Name" />
          </Tab>
          <Tab label="Authorized phone numbers">
            <AddPhoneNumberButton />
            <ImportPhoneNumberListButton />
            <ReferenceManyField
              reference={IDENTIFIERS.AUTHORIZED_PHONE_NUMBERS}
              target="tenant"
              label=""
              pagination={<Pagination />}
            >
              <Datagrid key="phoneNumber">
                <TextField source="phoneNumber" label="Phone number" />
                <DeleteButton redirect="1" />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          <Tab label="Users">
            <ReferenceManyField reference="users" target="tenantId" label="">
              <UserDatagrid {...props} />
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </Show>
      <Modal
        open={showCreateModal}
        title="Ajouter un numéro authorisé"
        onClose={() => {
          setShowCreateModal(false)
          refresh()
        }}
        reference={`tenants/${props.id}/authorizedphonenumbers`}
        method="post"
      >
        <TextInput source="phoneNumber" label="Phone number" />
      </Modal>
      <Modal
        open={showImportModal}
        title="Importer une liste de numéros authorisés"
        onClose={() => {
          setShowImportModal(false)
          refresh()
        }}
        reference={`tenants/${props.id}/authorizedphonenumbers/import`}
        method="post"
        isFormData
      >
        <FileInput source="attachments" accept="text/csv">
          <FileField source="src" title="title" />
        </FileInput>
      </Modal>
    </>
  )
}
