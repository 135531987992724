import React from 'react'
import {
  ReferenceField, NumberField,
  Show, Tab, TabbedShowLayout, TextField
} from 'react-admin'
import { ShowActionsEdit } from '../../config/components'
import DisplayMapFavorite from '../../components/DisplayMapFavorite'

const FavoriteShow = (props) => (
  <Show actions={<ShowActionsEdit />} {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="id" />
        <ReferenceField label="Profil" source="profileId" reference="users" link="show">
          <TextField source="phone" />
        </ReferenceField>
        <TextField source="name" label="Name" />
        <TextField source="type" label="Type" />
        <NumberField source="lat" label="Latitude" />
        <NumberField source="long" label="Longitude" />
        <TextField source="addressLabel" label="Address" />
      </Tab>
      <Tab label="Map">
        <DisplayMapFavorite />
      </Tab>

    </TabbedShowLayout>
  </Show>
)

export default FavoriteShow
