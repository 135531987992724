import React from 'react'
import { Edit, FormTab, TabbedForm, TextInput, TextField } from 'react-admin'

export const TenantEdit = (props) => (
  <Edit {...props}>
    <TabbedForm redirect="show">
      <FormTab label="Détails">
        <TextField source="id" />
        <TextInput source="name" label="Name" />
      </FormTab>
    </TabbedForm>
  </Edit>
)
