import * as React from 'react'

export default class DisplayMapReport extends React.Component {
  mapRef = React.createRef()
  state = {
    map: null,
  }

  componentDidMount() {
    const record = this.props.record
    const H = window.H
    const platform = new H.service.Platform({
      apikey: 'qcKy-J9wfKwyu9npVc5LXxIJSCTsEUu8egb3c3MedmU',
    })

    const defaultLayers = platform.createDefaultLayers()

    const start =
      '<svg width="24px" height="24px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
      '\t viewBox="0 0 271.953 271.953" style="enable-background:new 0 0 271.953 271.953;" xml:space="preserve">\n' +
      '<g>\n' +
      '\t<g>\n' +
      '\t\t<path style="fill:#010002;" d="M135.977,271.953c75.097,0,135.977-60.879,135.977-135.977S211.074,0,135.977,0S0,60.879,0,135.977\n' +
      '\t\t\tS60.879,271.953,135.977,271.953z M250.197,135.977c0,62.979-51.241,114.22-114.22,114.22s-114.22-51.241-114.22-114.22\n' +
      '\t\t\ts51.241-114.22,114.22-114.22S250.197,72.998,250.197,135.977z"/>\n' +
      '\t\t<path style="fill:#010002;" d="M112.295,205.031c2.692,1.115,5.434,1.659,8.235,1.659c5.662,0,11.183-2.208,15.344-6.375\n' +
      '\t\t\tl48.93-48.952c8.496-8.496,8.485-22.273-0.011-30.769l-48.957-48.952c-4.161-4.161-9.73-6.375-15.393-6.375\n' +
      '\t\t\tc-2.801,0-5.461,0.544-8.153,1.659c-8.126,3.367-13.255,11.297-13.255,20.097v97.903\n' +
      '\t\t\tC99.034,193.729,104.164,201.664,112.295,205.031z M120.791,88.613v-1.588l48.952,48.952l-48.952,48.952V88.613z"/>\n' +
      '\t</g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '</svg>\n'

    const finish =
      '<svg width="24px" height="24px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
      '\t viewBox="0 0 496 496" style="enable-background:new 0 0 496 496;" xml:space="preserve">\n' +
      '<g>\n' +
      '\t<g>\n' +
      '\t\t<g>\n' +
      '\t\t\t<path d="M428,24H100V8c0-4.418-3.582-8-8-8H68c-4.418,0-8,3.582-8,8v480c0,4.418,3.582,8,8,8h24c4.418,0,8-3.582,8-8V296h328\n' +
      '\t\t\t\tc4.418,0,8-3.582,8-8V32C436,27.582,432.418,24,428,24z M84,480h-8V16h8V480z M420,120h-80v80h80v80h-80v-80h-80v80h-80v-80h-80\n' +
      '\t\t\t\tv-80h80V40h80v80h80V40h80V120z"/>\n' +
      '\t\t\t<rect x="180" y="120" width="80" height="80"/>\n' +
      '\t\t</g>\n' +
      '\t</g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '</svg>\n'

    const warning =
      '<svg width="24px" height="24px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
      '\t viewBox="0 0 451.74 451.74" style="enable-background:new 0 0 451.74 451.74;" xml:space="preserve">\n' +
      '<path style="fill:#E24C4B;" d="M446.324,367.381L262.857,41.692c-15.644-28.444-58.311-28.444-73.956,0L5.435,367.381\n' +
      '\tc-15.644,28.444,4.267,64,36.978,64h365.511C442.057,429.959,461.968,395.825,446.324,367.381z"/>\n' +
      '<path style="fill:#FFFFFF;" d="M225.879,63.025l183.467,325.689H42.413L225.879,63.025L225.879,63.025z"/>\n' +
      '<g>\n' +
      '\t<path style="fill:#3F4448;" d="M196.013,212.359l11.378,75.378c1.422,8.533,8.533,15.644,18.489,15.644l0,0\n' +
      '\t\tc8.533,0,17.067-7.111,18.489-15.644l11.378-75.378c2.844-18.489-11.378-34.133-29.867-34.133l0,0\n' +
      '\t\tC207.39,178.225,194.59,193.87,196.013,212.359z"/>\n' +
      '\t<circle style="fill:#3F4448;" cx="225.879" cy="336.092" r="17.067"/>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '<g>\n' +
      '</g>\n' +
      '</svg>'

    const map = new H.Map(this.mapRef.current, defaultLayers.vector.normal.map, {
      center: { lat: 50, lng: 5 },
      zoom: 4,
      pixelRatio: window.devicePixelRatio || 1,
    })
    const group = new H.map.Group()
    // ROUTE & SECTIONS
    record.routes.forEach((route, i) =>
      route.sections.forEach((section, i2) => {
        if (i === 0 && i2 === 0 && section.polyline && section.polyline[0]) {
          // START
          const startIcon = new H.map.Icon(start)
          const startMarker = new H.map.Marker(
            {
              lat: section.polyline[0].lat,
              lng: section.polyline[0].long,
            },
            { icon: startIcon }
          )
          group.addObject(startMarker)
        }
        if (i === 0 && i2 === route.sections.length - 1 && section.polyline && section.polyline[0]) {
          // END
          const finishPoint = section.polyline[section.polyline.length - 1]
          console.log(finishPoint)
          const finishIcon = new H.map.Icon(finish)
          const finishMarker = new H.map.Marker(
            {
              lat: section.polyline[section.polyline.length - 1].lat,
              lng: section.polyline[section.polyline.length - 1].long,
            },
            { icon: finishIcon }
          )
          group.addObject(finishMarker)
        }

        const lineSection = new window.H.geo.LineString()
        section.polyline.forEach((coord) => {
          lineSection.pushPoint({
            lat: coord.lat,
            lng: coord.long,
          })
        })
        group.addObject(
          new H.map.Polyline(lineSection, {
            style: { strokeColor: i === 0 ? 'rgba(0,255,0,0.5)' : 'rgba(255,0,0,0.5)', lineWidth: i === 0 ? 8 : 5 },
          })
        )
      })
    )
    // BEFORE DRIVER LOCATION
    let beforeDriverLocationLine = new window.H.geo.LineString()
    if (record.beforeDriverLocation && record.beforeDriverLocation.length > 1) {
      record.beforeDriverLocation.forEach((coord) => {
        beforeDriverLocationLine.pushPoint({
          lat: coord.lat,
          lng: coord.long,
        })
      })
      group.addObject(
        new H.map.Polyline(beforeDriverLocationLine, {
          style: {
            strokeColor: 'black',
            lineDash: [1, 2],
            strokeWidth: 12,
          },
        })
      )
    }
    // AFTER DRIVER LOCATION
    let afterDriverLocationLine = new window.H.geo.LineString()
    if (record.afterDriverLocation && record.afterDriverLocation.length > 1) {
      record.afterDriverLocation.forEach((coord) => {
        afterDriverLocationLine.pushPoint({
          lat: coord.lat,
          lng: coord.long,
        })
      })
      group.addObject(
        new H.map.Polyline(afterDriverLocationLine, {
          style: {
            strokeColor: 'orange',
            lineDash: [1, 2],
            strokeWidth: 12,
          },
        })
      )
    }
    // REPORT LOCATION
    // Add the first marker
    if (record.reportLocation && record.reportLocation.lat && record.reportLocation.long) {
      const warningIcon = new H.map.Icon(warning)
      const warningMarker = new H.map.Marker(
        {
          lat: record.reportLocation.lat,
          lng: record.reportLocation.long,
        },
        { icon: warningIcon }
      )
      group.addObject(warningMarker)
    }
    map.addObject(group)
    // set map size to bounding box
    map.getViewModel().setLookAtData({
      bounds: group.getBoundingBox(),
    })

    // MapEvents enables the event system
    // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
    // This variable is unused and is present for explanatory purposes
    new H.mapevents.Behavior(new H.mapevents.MapEvents(map))

    // Create the default UI components to allow the user to interact with them
    // This variable is unused
    H.ui.UI.createDefault(map, defaultLayers)

    this.setState({ map })
  }

  componentWillUnmount() {
    this.state.map.dispose()
  }

  componentDidUpdate() {
    const { lat, lng, zoom } = this.props

    if (this.map) {
      // prevent the unnecessary map updates by debouncing the
      // setZoom and setCenter calls
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.map.setZoom(zoom)
        this.map.setCenter({ lat, lng })
      }, 100)
    }
  }

  render() {
    return <div ref={this.mapRef} style={{ height: '80vh', width: '80vw' }} />
  }
}
