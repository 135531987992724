export const IDENTIFIERS = {
  USER: 'users',
  SUBSCRIPTION: 'subscriptions',
  REPORT: 'reports',
  TRUCK: 'trucks',
  FAVORITE: 'favorites',
  TENANT: 'tenants',
  AUTHORIZED_PHONE_NUMBERS: 'authorizedphonenumbers',
}

export const resources = {
  [[IDENTIFIERS.USER]]: {
    name: IDENTIFIERS.USER,
    url: 'users',
  },
  [[IDENTIFIERS.SUBSCRIPTION]]: {
    name: IDENTIFIERS.SUBSCRIPTION,
    url: 'subscriptions',
  },
  [[IDENTIFIERS.REPORT]]: {
    name: IDENTIFIERS.REPORT,
    url: 'reports',
  },
  [[IDENTIFIERS.TRUCK]]: {
    name: IDENTIFIERS.TRUCK,
    url: 'trucks',
  },
  [[IDENTIFIERS.FAVORITE]]: {
    name: IDENTIFIERS.FAVORITE,
    url: 'favorites',
  },
  [[IDENTIFIERS.TENANT]]: {
    name: IDENTIFIERS.TENANT,
    url: 'tenants',
  },
  [[IDENTIFIERS.AUTHORIZED_PHONE_NUMBERS]]: {
    name: IDENTIFIERS.AUTHORIZED_PHONE_NUMBERS,
    url: 'authorizedphonenumbers',
  },
}

/**
 * Returns the URL according to the resource, or the original parameter if it's not found
 * @param {string} name Resource name
 */
export const getResourceUrl = (name) => {
  if (resources[name]) {
    return resources[name].url
  }
  return name
}

/**
 * Returns the label according to the resource, or the original parameter if it's not found
 * @param {string} name Resource name
 */
export const getResourceLabel = (name) => {
  if (resources[name]) {
    return resources[name].label
  }
  return name
}

export default {
  IDENTIFIERS,
  resources,
  getResourceLabel,
  getResourceUrl,
}
