import React from 'react'
import {
  Create, FormTab, TabbedForm, TextInput,
} from 'react-admin'

const UserCreate = (props) => (
  <Create {...props}>
    <TabbedForm redirect="show">
      <FormTab label="Détails">
        <TextInput source="phone" label="Téléphone" />
      </FormTab>
    </TabbedForm>
  </Create>
)

export default UserCreate
